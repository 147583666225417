<!-- 电力极值报表 -->
<template>
  <div id="extreme-report">
    <!-- <div class="tree">
      <div class="tree-title">变配电站</div>
      <el-tree
        ref="tree"
        :default-expand-all="false"
        :show-checkbox="true"
        :data="treeData"
        :check-on-click-node="false"
        :props="defaultProps"
        node-key="id"
        @node-click="handleCheck"
      ></el-tree>
    </div> -->
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>电力极值报表</span>
      </div>
      <div class="content">
        <ul class="tabs">
          <li v-for="(item, index) in tabsData" :key="index" :class="select === index ? 'select' : ''"
            @click="switchTabs(index)">
            {{ item }}
          </li>
        </ul>
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="formData">
            <el-form-item label="日期" class="query-date">
              <el-date-picker :append-to-body="false" placeholder="请选择日期"  value-format="yyyy-MM-dd"  type="date" v-model="formData.time"></el-date-picker>
            </el-form-item>
            <el-form-item label="电力类别">
              <el-select :popper-append-to-body="false" v-model="formData.type1" placeholder="请选择">
                <el-option v-for="item in typeList" :key="item.value" :label="item.name"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="回路名称">
              <el-select :popper-append-to-body="false" v-model="formData.deviceNumber" placeholder="请选择">
                <el-option v-for="(item, index) in loopList" :key="index" :label="item.loopName +' ('+ item.loopNumber+ ')'"
                  :value="item.deviceNumber"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <!-- <el-button type="primary" icon="el-icon-arrow-left">上一日</el-button>
              <el-button type="primary">下一日<i class="el-icon-arrow-right el-icon--right"></i></el-button> -->
              <el-button @click="searchList" type="primary" icon="el-icon-search">查询</el-button>
              <!-- <el-button type="primary" icon="el-icon-download" @click="exportDialog = true">导出</el-button> -->
            </el-form-item>
          </el-form>
        </div>

        <el-table class="table" :data="tableData">
          <el-table-column align="center" prop="loopName" label="回路名称">
          </el-table-column>
          <el-table-column align="center" prop="nowTime" label="时间">
            <template slot-scope="scope">
              <p v-if="scope.row.nowTime">

                {{ select==0? scope.row.nowTime.slice(10, 19) :scope.row.nowTime.slice(0, 10) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column align="center" :label="`${getNameByValue(formData.type1)}`">
            <el-table-column align="center" prop="max" label="最大值">
            </el-table-column>
            <el-table-column align="center" prop="min" label="最小值">
            </el-table-column>
          </el-table-column>

        </el-table>
      </div>
    </div>
    <el-dialog title="导出" :visible.sync="exportDialog" width="436px">
      <div class="dialog-content">确定要导出数据吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportDialog = false">取 消</el-button>
        <el-button type="primary" @click="exportDialog = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { nextTick } from "vue";
import { emissionsCarbonApi } from '@/api/emissionsCarbon.js'
export default {
  name: "",

  data() {
    return {
      formData: {
        time: "",
        type1: 1,
        deviceNumber:'',
      },
      exportDialog: false,
      tabsData: ["日报", "月报"],
      tableData: [
        {
          name: "*主进线柜",
          zero: "20",
          one: "50",
          two: "42",
          three: "40",
          four: "50",
          five: "42",
          six: "40",
          seven: "50",
          eight: "42",
          nine: "50",
          ten: "42",
          eleven: "50",
          twelve: "42",
        },

      ],
      select: 0,
      unit:'(A)',
      typeList: [
        {
          name: 'A相电流',
          value: 1
        },
        {
          name: 'B相电流',
          value: 2
        },
        {
          name: 'C相电流',
          value: 3
        },
        {
          name: 'A相电压',
          value: 4
        },
        {
          name: 'B相电压',
          value: 5
        },
        {
          name: 'C相电压',
          value: 6
        },
        {
          name: '有功功率',
          value: 7
        },
        {
          name: '无功功率',
          value: 8
        },
        {
          name: '功率因数',
          value: 9
        },
      ],
      stationNumber: '',
      monthStart:'',
      monthEnd:'',
      loopList: [],


    };
  },

  mounted() {
    // this.queryOperation();
    this.stationNumber=sessionStorage.getItem('stationNumber')
    // this.stationNumber = 'ZD0279-01'
    emissionsCarbonApi.getLoopList({ stationNumber: this.stationNumber, position:3 }).then(res => {
      this.loopList = res.data
      this.formData.deviceNumber = res.data[0].deviceNumber
      this.getExtDayList()
    })

  },
  created(){
    this.$store.commit('increment', '电力监测')
    this.$store.commit('selectChild', '低压侧')
    this.$store.commit("selectChildren", "电力极值报表");
  },

  methods: {
    searchList(){
      if(this.select==0){
        this.getExtDayList()
      }else{
        this.getExtMonthList()
      }
    },
    switchTabs(index) {
      this.select = index;
      this.formData.time=''
      if(index===0){
        this.getExtDayList()
      }else{
        this.getExtMonthList()
      }
    },
    getExtMonthList() {
      this.getMonthStartAndEnd(this.formData.time)
      let obj = {
        stationNumber: this.stationNumber,
        type1: this.formData.type1,
        monthStart: this.monthStart,
        monthEnd: this.monthEnd,
        deviceNumber:this.formData.deviceNumber
      }
      
      emissionsCarbonApi.getExtMonthList(obj).then(res => {
        if(this.formData.type1 ==1){
          this.tableData=res.data.ia
        }
        if(this.formData.type1 ==2){
          this.tableData=res.data.ib
        }
        if(this.formData.type1 ==3){
          this.tableData=res.data.ic
        }
        if(this.formData.type1 ==4){
          this.tableData=res.data.ua
        }
        if(this.formData.type1 ==5){
          this.tableData=res.data.ub
        }
        if(this.formData.type1 ==6){
          this.tableData=res.data.uc
        }
        if(this.formData.type1 ==7){
          this.tableData=res.data.pe
        }
        if(this.formData.type1 ==8){
          this.tableData=res.data.qe
        }
        if(this.formData.type1 ==9){
          this.tableData=res.data.cosqes
        }
       
        
      })
    },
    getExtDayList() {
      let obj = {
        stationNumber: this.stationNumber,
        type1: this.formData.type1,
        startTime: this.formData.time,
        endTime: this.formData.time,
        deviceNumber:this.formData.deviceNumber
      }
      if (!this.formData.time) {
        var date = new Date();
        date.setDate(date.getDate());
        var yesterday = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        obj.startTime = yesterday
        obj.endTime = yesterday
      }
      console.log(obj);
      emissionsCarbonApi.getExtDayList(obj).then(res => {
        if(this.formData.type1 ==1){
          this.tableData=res.data.ia
        }
        if(this.formData.type1 ==2){
          this.tableData=res.data.ib
        }
        if(this.formData.type1 ==3){
          this.tableData=res.data.ic
        }
        if(this.formData.type1 ==4){
          this.tableData=res.data.ua
        }
        if(this.formData.type1 ==5){
          this.tableData=res.data.ub
        }
        if(this.formData.type1 ==6){
          this.tableData=res.data.uc
        }
        if(this.formData.type1 ==7){
          this.tableData=res.data.pe
        }
        if(this.formData.type1 ==8){
          this.tableData=res.data.qe
        }
        if(this.formData.type1 ==9){
          this.tableData=res.data.cosqes
        }
       
        
      })
    },
    getNameByValue(targetValue) {
     
      
  
    for (let i = 0; i < this.typeList.length; i++) {
      if (this.typeList[i].value === targetValue) {
        let unit;
        if( targetValue <= 3 && targetValue>=1 ){
       unit='(A)'
      }else if(targetValue <= 6 && targetValue >= 4 ){
       unit='(V)'
      }else if(targetValue==7){
        unit='(Wh)'
      }else if(targetValue==8){
        unit='(var)'
      }else{
        unit=''
      }
      console.log(targetValue,unit);
        
        return this.typeList[i].name + unit;
      }
    }
    // 如果未找到匹配的值，则返回 null 或者适当的默认值
    return null;
  },
  getMonthStartAndEnd(dateString) {
      let date;
    if (dateString) {
        date = new Date(dateString);
    } else {
        date = new Date();
    }
    
    const year = date.getFullYear();
    const month = date.getMonth() + 1; 
    const firstDay = new Date(year, month - 1, 2); 
    const lastDay = new Date(year, month, 1); 

    const formatDate = (d) => {
        return d.toISOString().slice(0, 10);
    };
    this.monthStart = formatDate(firstDay);
    this.monthEnd= formatDate(lastDay);
    console.log(this.staTime,this.endTime);
   
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#extreme-report {
  width: 100%;
  min-width: 1621px;
  min-height: 903px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
}

/* tree */
.tree :deep() .el-tree {
  width: 240px;
  color: #aed6ff;
  background-color: rgba(255, 255, 255, 0);
  padding: 0 0 10px 21px;
  box-sizing: border-box;
}

.tree-title {
  font-weight: 700;
  color: #d8e3ff;
  font-size: 14px;
  margin: 24px 0 21px 21px;
}

::v-deep .el-tree {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .el-tree-node {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .is-current {
  background: #03183f !important;
  color: #ffc74a;
}

::v-deep .is-checked {
  background: #03183f !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183f;
  /* color: #FFC74A; */
}

::v-deep.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #03183f;
}

::v-deep .el-tree-node {
  background-color: #03183f;
}

::v-deep .el-tree-node:focus>.el-tree-node__content {
  background-color: #03183f;
}

.main {
  width: 1376px;
  padding: 20px;
  box-sizing: border-box;
}

.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.title img {
  width: 20px;
  height: 20px;
}

.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}

/* tabs */
.tabs {
  width: 1336px;
  background-color: rgb(4, 25, 66);
  display: flex;
}

.tabs li {
  width: 85px;
  height: 40px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0);
  font-family: Microsoft YaHei;
  color: #e8f4ff;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.tabs li:hover {

  background-color: #002c6c;
  color: #ffc74a;
}

.tabs .select {
  background-color: #002c6c;
  color: #ffc74a;
}

/* 查询表单 */
.query-form {
  display: flex;
  align-items: center;
  margin: 18px 0 20px 0;
}

.form :deep() .el-icon-date {
  color: #aed6ff;
}

.form :deep().el-form-item__label {
  color: #aed6ff;
  font-size: 12px;
}

.form :deep() .el-form-item {
  margin: 0 8px 0 0;
}

.form :deep() .el-input {
  /* width: 132px; */
}

/* input */
.form :deep() .el-input .el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #aed6ff;
}

/* 表格 */
/* 设置表头的背景颜色 */
.table :deep() thead.is-group th.el-table__cell {
  background: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}

.table :deep() .el-table__cell {
  padding: 4px 0;
}

.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}

::v-deep .el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid rgb(19, 43, 83) !important;
}

::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}

.table ::v-deep .el-table__body tr:hover>td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table__fixed-right::before {
  height: 0px;
}

::v-deep .el-table__body tr.hover-row>td.el-table__cell {
  background-color: #081f42 !important;
}

/* 滚动条样式 */

/* 更改 el-table 横向滚动条的样式 */
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  height: 8px;
  /* 设置滚动条高度 */
  background-color: #09234e;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #071734;
}

::v-deep .el-dialog .el-dialog__header {
  background-color: #05153a;
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #c9d4f1;
}

::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-dialog__footer {
  padding-top: 0;
}

.dialog-content {
  font-size: 16px;
  color: #c9d4f1;
  font-weight: 700;
  margin: 42px 0 64px 32px;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialog-footer button {
  width: 106px;
  height: 36px;
  background: #2142a1;
  border: 0;
  color: #ffffff;
  font-size: 13px;
}
::v-deep .el-table__body-wrapper {
  max-height: 75vh;
  overflow: auto;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
::v-deep .el-table--border::after, .el-table--group::after{
  width: 0;
}

</style>
